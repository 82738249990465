<template>
  <div class="col-md-12">
    <div class="col-md-12 my-5 filter">
      <filterPedidos
        :dataInit.sync="fDataInit"
        :dataFinish.sync="fDataFinish"
        :status.sync="fStatus"
        @getOrders="getOrders"
        @clear="clear"
        @clStatus = "clStatus"
        @clPay = "clPay"
        @clFilter = "clFilter"
      ></filterPedidos>
    </div>
    <div class="col-md-12">
      <div v-if="this.sizeWindow > 767">
        <div class="row" v-if="orders">
          <div class="col-md-1">
            <p class="titulo_dash mb-2 nav">Código</p>
          </div>
          <div class="col-md-2">
            <p class="titulo_dash mb-2 nav">Serviços</p>
          </div>
          <div class="col-md-2">
            <p class="titulo_dash mb-2 mr-2 nav">Data - hora</p>
          </div>
          <div class="col-md-1">
            <p class="titulo_dash mb-2 nav">Valor</p>
          </div>
          <div class="col-md-2 centers">
            <p class="text-center titulo_dash mb-2 nav">Status</p>
          </div>
          <div class="col-md-2">
            <p class="titulo_dash mb-2 nav">Operador</p>
          </div>
          <div class="col-md-2">
            <p class="titulo_dash mb-2 nav">Anexo</p>
          </div>
        </div>
      </div>
      <div v-if="!load && !orders.length">
        <p class="my-5 text-center w-100" style="font-size:16px; color:#ffff;">
          Nenhum registro para ser exibido.
        </p>
      </div>
    </div>
    <transition-group name="list-complete" tag="div" v-if="!load">
      <div
        class="col-md-12 box mb-3 list-complete-item"
        v-for="(item, index) in orders"
        :key="index + 1"
      >
        <div class="row align-items-center" :style=" sizeWindow > 767 ? 'height: 60px;' : 'height: auto;'">
          <div class="col-md-1 d-flex" :class="item.isBB ? 'justify-content-center align-items-center' : ''" id="linha-item">
            <img class="bb" v-if="item.isBB" src="@/assets/image/icones/bb.png" alt="bb">
            <div v-else>
              <p v-if="sizeWindow <= 767" class="numeroPedido mb-0">Código</p>
              <p class="numeroPedido mb-0">#{{ item.id }}</p>
            </div>
          </div>
          <div class="col-md-2" id="linha-item">
            <p v-if="sizeWindow <= 767" class="numeroPedido mb-0">Serviço</p>
            <p
              class="servico_descricao mb-0 service_name"
              :class="`service_name${index}`"
              v-if="item.servicesOrder.length > 0"
            >
              {{ itemNoCancelled(item) }}
            </p>
          </div>
          <div class="col-md-2" id="linha-item">
            <p v-if="sizeWindow <= 767" class="numeroPedido mb-0">Data/Hora</p>
            <p class="servico_descricao mb-0">
              {{ getDate(item.registerTime) }}
            </p>
          </div>
          <div class="col-md-1" id="linha-item">
            <p v-if="sizeWindow <= 767" class="numeroPedido mb-0">Valor</p>
            <p class="servico_descricao mb-0 text-center" id="precoServico">
              {{ item.total | currency }}
            </p>
          </div>

          <div class="col-md-2" id="statusContainer">
            <p v-if="sizeWindow <= 767" class="numeroPedido mb-0 ">Status</p>
            <StatusPedidos class="col-md-0" :ident="item.status - 1" />
            <div
              v-if="existsMessageCancellation(item) || item.status == 1"
              class="col-md-2"
              id="tooltip"
            >
              <i
                class="fas fa-info-circle mt-0"
                style="color: #fff;"
                data-toggle="tooltip"
                data-placement="right"
                :title="tooltipMessage(item)"
              >
              </i>
            </div>
          </div>
          <div class="col-md-2" id="linha-item">
            <p v-if="sizeWindow <= 767" class="numeroPedido mb-0">Operador</p>
            <p class="numeroPedido mb-0">{{ item.salesMan }}</p>
          </div>
          <div class="col-md-2">
            <div class="row">
              <div class="col-md-4">
                <img v-if="showServiceBB" class="bb" src="@/assets/image/icones/bb.png" alt="bb">
              </div>
              <div class="col-md-4" id="deleteContainer">
                <font-awesome-icon 
                  v-if="(profile == 1 || profile == 2) && item.status != 1" 
                  class="fas delete" 
                  icon="fa-trash-can" 
                  @click="showModal(item.id)"
                />
              </div>
              <div class="col-md-4" id="eyeContainer">
                <p v-if="sizeWindow <= 767" class="numeroPedido mb-0">Anexo</p>
                <i
                  :class="eyeID != item.id ? 'fas fa-eye' : 'fas fa-eye-slash'"
                  @click.prevent="showDpShow(index, item.id)"
                ></i>
              </div>
            </div>
          </div>
        </div>
        <dropdown
          class="dropdownCp"
          :class="`dp${index}`"
          :pedidos="item"
          :total="item.total"
          :prints="item.prints"
          :status="item.status"
          :index="index"
          :itemId="item.id"
          @get-orders="(index, itemId) => reloadGetOrders(index, itemId)"
          @showModalDegustacaoServico="showModalDegustacaoServico"
        />
      </div>
    </transition-group>
    <half-circle-spinner
      v-else
      class="mx-auto my-5"
      :animation-duration="1000"
      :size="100"
      color="#fff"
    />
    <vue-ads-pagination
      :total-items="numberOfOrders"
      :max-visible-pages="4"
      :page="page"
      @page-change="pageChange"
      @range-change="rangeChange"
    >
      <template slot-scope="props">
        <div  class="vue-ads-pr-2 vue-ads-leading-loose">
          Pedidos {{ props.start }} de {{ props.end }} no total de
          {{ props.total }}
        </div>
      </template>
      <template slot="buttons" slot-scope="props">
        <vue-ads-page-button
          v-for="(button, key) in props.buttons"
          :key="key"
          v-bind="button"
          @page-change="pageChange(button.page)"
        />
      </template>
    </vue-ads-pagination>
    <modalPedidoDelete :id.sync="id" @close-modal="closeModalDelete"/>
    <modalDegustacao/>
    <modalDegustacaoServico/>
  </div>
</template>

<script>
import filterPedidos from "./filterPedidos.vue";
import VueAdsPagination, { VueAdsPageButton } from "vue-ads-pagination";
import dropdown from "./pedidosCp/dropdown";
import StatusPedidos from "./StatusPedidos.vue";
import modalPedidoDelete from "./pedidosCp/modalPedidoDelete";
import modalDegustacao from "@/components/modal/modalDegustacao";
import modalDegustacaoServico from "@/components/modal/modalDegustacaoServico";
import { HalfCircleSpinner } from "epic-spinners";
import HTTP from "@/api_system";
export default {
  components: {
    dropdown,
    modalPedidoDelete,
    modalDegustacao,
    modalDegustacaoServico,
    VueAdsPagination,
    VueAdsPageButton,
    StatusPedidos,
    filterPedidos,
    HalfCircleSpinner
  },
  data() {
    return {
      showServiceBB: false,
      state: "",
      eyeID: "",
      fDataInit: "",
      fDataFinish: "",
      fStatus: "",
      fPay:"",
      fId:"",
      orders: [],
      numberOfOrders: 0,
      page: 0,
      load: true,
      comparData:"",
      sizeWindow: 0,
      id: ""
    };
  },

  methods: {
    itemNoCancelled(order) {
      var message = "";
      if (order != null && order.servicesOrder != null) {
        for (let i = 0; i < order.servicesOrder.length; i++) {
          if (order.servicesOrder[i].messageCancellation == null) {
            message = order.servicesOrder[i].serviceName;
            break; 
          }      
        }
      }
      return message;
    },
    enableTooltip() {
      $(function() {
        $('[data-toggle="tooltip"]').tooltip();
      });
    },
    tooltipMessage(order){
      if(order.messageCancellation != null) {
        return order.messageCancellation;
      } else {
        var count = 0;
        if (order != null && order.servicesOrder != null) {
          order.servicesOrder.forEach((item, index) => {
            if (item.messageCancellation != null) {
              count += 1;
            }
          });
        }
        return count > 1 ? `O pedido possui ${count} serviços cancelados` : `O pedido possui ${count} serviço cancelado`
      }
    },
    existsMessageCancellation(order){
      if (order != null && order.servicesOrder != null) {
        return order.servicesOrder.find(item => item.messageCancellation != null);
      }
    },
    showModal(modalId) {
      this.id = modalId;
      if (this.$store.getters.get_tenant.type == 4 && this.$store.getters.getUser.profile != 1) {
        $("#modalDegustacao").modal("show");
      } else {
        $("#modalPedidoDelete").modal("show");
      }
    },
    closeModalDelete() {
      $("#modalPedidoDelete").modal("hide");
      this.getOrders(this.page)
    },
    reloadGetOrders(index, itemId) {
      this.getOrders(this.page)
      setTimeout(() => {
        this.showDpShow(index, itemId) 
      }, 1200);             
    },
    dataAtualFormatada(){
      var data = new Date(),
      dia  = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0'+dia : dia,
      mes  = (data.getMonth()+1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0'+mes : mes,
      anoF = data.getFullYear();
      this.comparData = anoF+"-"+mesF+"-"+diaF;
    },
    clStatus(value){
      this.fStatus = value
    },
    clPay(value){
      this.fPay = value
    },
    clFilter(value){
      this.fId = value
    },
    hideEye() {
      document.getElementById("olhoFechado").style.display = "none";
    },
    clear() {
      this.fDataInit = "",
      this.fDataFinish = "",
      this.fStatus = "",
      this.fPay = "",
      this.fId = ""
      this.pageChange(0);
    },
    showDpShow(index, id) {
      this.eyeID = id;
      var show;
      var iconShow;
      var currentIndx;
      if ($(`.dp${index}`).is(":visible")) {
        show = "slideUp";
        iconShow = "fadeIn";
        this.eyeID = "";
      } else {
        show = "slideDown";
        iconShow = "fadeOut";
      }

      $(`.dp${index}`).velocity(show, {
        display: `${show == "slideUp" ? "none" : "flex"}`,
        duration: 500
      });
      $(`.service_name${index}`).velocity(iconShow, {
        display: `${iconShow == "fadeOut" ? "none" : ""}`,
        duration: 0
      });

      var currentIndx = index;
    },
    getDate(val) {
      var data = new Date(val);
      return `${data.getDate()}/${data.getMonth() +
        1}/${data.getFullYear()} - ${data.getHours()}:${
        data.getMinutes() < 10 ? "0" + data.getMinutes() : data.getMinutes()
      }`;
    },
    rangeChange(start, end) {
      // this.getPedidos();
    },
    getOrders(page) {
      this.orders = [];
      let json = {
        'Dinheiro' : 0,
        'Cartão': 1,
        'Transferencia/Pix': 2,
      }
        if (this.fDataFinish || this.fDataInit && !this.fId && !this.fPay  && !this.fStatus) {
          if (moment(this.fDataInit).isAfter(this.comparData) || moment(this.fDataFinish).isAfter(this.comparData)){
          this.toast(
              "Favor informar datas válidas",
              this.$toast.error
            );
            this.orders = [];
            this.numberOfOrders = 0;
            return;
        }
          else if (
            this.fDataInit !== "" &&
            this.fDataFinish !== ""
          ) {
            this.orders = [];
            this.numberOfOrders = 0;
          }else {
            this.toast(
              "Favor informar todos parâmetros para consulta",
              this.$toast.error
            );
            this.orders = [];
            this.numberOfOrders = 0;
            return;
          }
        }

      this.load = true;
      this.page = page;

      // let getOrders = `services/app/Order/GetListOrders?paginaAtual=${this.page + 1}`
      const { fDataInit, fDataFinish, fStatus, fPay, fId } = this
      const itemPay = []
      if(this.fPay.length > 0) {
        this.fPay.forEach(element => {
          itemPay.push(json[element])
        });
      }
      let getOrders = `services/app/Order/GetListOrders?paginaAtual=${this.page + 1}${this.fDataInit ? `&dateInitial=${this.fDataInit}` : ''}${this.fDataFinish ? `&dateFinale=${this.fDataFinish}` : ''}${this.fStatus ? `&orderStatus=${this.fStatus}` : ''}${this.fPay && itemPay[0] < 1 ? `&coins=${itemPay[0]}` : ''}${this.fPay && itemPay[1] ? `&coins=${itemPay[1]}` : ''}${this.fPay && itemPay[2] ? `&coins=${itemPay[2]}` : ''}${this.fId ? `&orderId=${this.fId}` : ''}`

      HTTP.get(getOrders, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.load = false;
          this.orders = response.data.result.orders;
          this.numberOfOrders = response.data.result.numberOfpedidos;
        })
        .catch(error => {
          this.load = false;
          this.$notify({
            group: "erros",
            type: "error",
            text: `<i class="icon ion-close-circled"></i>Ops algo deu errado`
          });
        });
    },
    pageChange(page) {
      this.getOrders(page);
      this.page = page;
    },
    showModalDegustacaoServico() {
      $("#modalDegustacaoServico").modal("show");
    }
  },
  mounted() {
    this.sizeWindow = $(window).width();
    this.getOrders(0);
    this.dataAtualFormatada()
  },
  computed: {
    profile() {
      return this.$store.getters.getUser.profile;
    }
  },
  watch: {
    cpDelete: function() {
      if (this.cpDelete != "") {
        setTimeout(() => {
          $(`#${this.cpDelete}`).modal("show");
        }, 100);
      }
    }
  }
};
</script>

<style scoped>
.dropdownCp {
  display: none;
}
#statusContainer {
  border-right: 2px #2872ab solid;
  height: 60%;
  display: flex;
  align-items: center;
}
.service_name {
  opacity: 1;
}
.col-md-2 {
  justify-content: center;
  display: flex;
}

.fas {
  font-size: 20px;
  transition: all 0.2s linear;
  color: #175d93;
  cursor: pointer;
}
.fas:hover {
  color: #fff;
}
.vue-ads-cursor-default {
  background-color: transparent;
}

.vue-ads-leading-loose {
  color: white;
}
.nav {
  font-size: 12px;
}
.pedidosVue {
  background: #2474b2;
}
.box {
  min-height: 60px;
  border: none;
  border-radius: 10px;
  background: #357eb8;
}
#linha-item {
  border-right: 2px #2872ab solid;
  height: 60%;
  display: flex;
  align-items: center;
}
.numeroPedido {
  font-size: 16px;
  font-weight: 400;
  color: #fff;
}
#precoServico {
  font-size: 16px;
  font-weight: 400;
}
.servico_descricao {
  color: #fff;
  font-weight: 400;
  font-size: 14px;
}
.bolder {
  font-weight: 700;
  font-size: 14px;
}

.filter div {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}

.filter p {
  margin: 0;
}

.filter p.status {
  position: absolute;
  left: 65px;
}
.filter p.date {
  position: relative;
  right: 7px;
}

.filter input {
  height: 20px;
  width: 169px;
  background: #357eb8;
  border: 1px solid #e0a638;
  padding: 15px 0 15px 5px;
  color: #fff;
  outline: none;
}

.filter select {
  height: 30px;
  background-color: #357eb8;
  border: 1px solid #e0a638;
  color: #fff;
  /* padding: 15px 0 15px 5px; */
  width: 200px;
  padding-left: 5px;
  outline: none;
}

.filter button {
  width: 100%;
  border-radius: 7px;
  font-weight: 500;
  color: #ffff;
  -webkit-box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  box-shadow: 0px 3px 6px 0 rgb(68 68 68 / 30%);
  -webkit-transition: all 0.2s linear;
  transition: all 0.2s linear;
  font-size: 14px;
  background-color: #f38235;
  border: none;
  height: 34px;
}

.filter button.clear {
  background-color: #f65757;
}
.filter button.clear:hover {
  background-color: #e67979;
}

.filter button:hover {
  background-color: #f4ab7b;
}
.centers {
  display: flex;
  align-items: center;
  justify-content: center;
}
.delete {
  font-size: 20px;
  transition: all 0.2s linear;
  cursor: pointer; 
}
.delete:hover {
  color: #f65757;
}
#tooltip {
  display: flex;
  float: right !important;
  width: 20px !important;
  margin-left: 5%;
}
.bb {
  width: 20px;
}

@media screen and (max-width:767px) {
  #linha-item{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border: none;
  }
  #statusContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 40px;
    border: none;
  }
  #eyeContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    border: none;
  }
  #deleteContainer{
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    height: 20px;
    border: none;
  }
  .filter{
    margin-bottom: 1rem!important;
    margin-top: 1rem!important;
  }
  .vue-ads-flex {
    flex-wrap: wrap;
  }

}
</style>
