<template>
    <modal
      :modalId="`modalPedidoDelete`"
      :load="false"
      :typeLg="`modal_md`"
    >
      <div class="col-md-12 mb-1 label">
        <label class="label_default label_blue label_lg align-middle">
            <strong>Deseja realmente cancelar o pedido {{ id }} ?</strong>
        </label>
      </div>
      <div class="row m-auto col-md-12">
      <div class="col-md-12 ml-auto">
        <div class="row">
          <div class="col-md-6 m-auto">
            <button
              type="button"
              @click="deleteOrder(id)"
              :class="`btn ${'btn_delete'}` "
            >
              Cancelar Pedido
            </button>
          </div>
          <div class="col-md-5 m-auto">
            <button
              type="button"
              class="btn btn_secondary"
              @click.prevent="closeModal()"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    </modal>
  </template>

<script>
import HTTP from '@/api_system'
export default {
  props: ['id'],
  components: {
    modal: () => import('@/components/modal/modalDefault.vue')
  },
  methods: {
    closeModal () {
      $('#modalPedidoDelete').modal('hide')
    },
    deleteOrder () {
      HTTP.post(`services/app/Order/DiscardOrderFinished?orderId=${this.id}`, '',
        {
          headers: {
            Authorization: `Bearer ${this.$store.getters.getterToken}`
          }
        })
        .then(() => {
          this.toast('Pedido cancelado', this.$toast.success)
          this.$nextTick(() => {
            this.$emit('close-modal')
          })
        })
        .catch(error => {
          this.toast(
            error?.response?.data?.error?.message ?? 'Ops algo deu errado',
            this.$toast.error
          );
        })
    }
  }
}
</script>

  <style scoped src="@/assets/style/modal.css"></style>
  <style scoped>
  .b_danger {
    color: red;
  }
  .label {
    height: 100px;
    text-align: center;
    padding-top: 35px;
  }
  </style>
