<template>
    <modal
      :modalId="id"
      :load="false"
      :typeLg="`modal_md`"
      @closeModal="closeModal"
    >
      <div class="col-md-12 mb-1 label">
        <label class="label_default label_blue label_lg align-middle">
            <strong>Deseja realmente cancelar o serviço <br> {{ serviceName }} ?</strong>
        </label>
      </div>
      <div class="row m-auto col-md-12">
      <div class="col-md-12 ml-auto">
        <div class="row">
          <div class="col-md-6 m-auto">
            <button
              type="button"
              @click="deleteService()"
              :class="`btn ${'btn_delete'}` "
            >
              Cancelar Serviço
            </button>
          </div>
          <div class="col-md-5 m-auto">
            <button
              type="button"
              class="btn btn_secondary"
              @click.prevent="closeModal()"
            >
              Fechar
            </button>
          </div>
        </div>
      </div>
    </div>
    </modal>
  </template>
  
  <script>
  import HTTP from "@/api_system";
  export default {
    props: ['id','orderServiceId', 'serviceName'],
    components: {
      modal: () => import("@/components/modal/modalDefault.vue"),
    },
    data() {
    return {
      message: "O serviço foi cancelado pelo franqueado"
    };
  },
    methods: {
      closeModal() {
        $(`#${this.id}`).modal('hide');
      },
      deleteService() {
        HTTP.delete(`services/app/OrdersServices/Delete?orderServiceId=${this.orderServiceId}&MessageCancelation=${this.message}`, {
        headers: {
          Authorization: `Bearer ${this.$store.getters.getterToken}`
        }
      })
        .then(response => {
          this.toast("Serviço cancelado", this.$toast.success);
          this.$nextTick(() => {
            this.closeModal()
            this.$emit('reload-orders');
          });
        })
        .catch(error => {
          this.toast("Ops algo deu errado", this.$toast.error);
          this.closeModal()
        });
      }
    }
  };
  </script>
  
  <style scoped src="@/assets/style/modal.css"></style>
  <style scoped>
  .b_danger {
    color: red;
  }
  .label {
    height: 100px;
    text-align: center;
    padding-top: 35px;
  }
  </style>
  